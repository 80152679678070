export const actions = [
    {
        label: 'Update Data',
        condition: 'show_update_data',
        action: 'update_data',
        icon: 'icon-svg icon-edit',
        order: 6
    },
    {
        label: 'Edit Policy Details',
        condition: 'show_edit_policy_details',
        action: 'edit_policy_details',
        icon: 'icon-svg icon-edit',
        order: 5
    },
    {
        label: 'Edit Refund Details',
        condition: 'show_edit_refund_details',
        action: 'edit_refund_details',
        icon: 'icon-svg icon-edit',
        order: 3
    },
    {
        label: 'View Refund Details',
        condition: 'show_view_refund_details',
        action: 'view_refund_details',
        icon: 'icon-svg icon-edit',
        order: 4
    },
    {
        label: 'Edit Cancellation Details',
        condition: 'show_edit_cancellation_details',
        action: 'edit_cancellation_details',
        icon: 'icon-svg icon-edit',
        order: 1
    },
    {
        label: 'View Cancellation Details',
        condition: 'show_view_cancellation_details',
        action: 'view_cancellation_details',
        icon: 'icon-svg icon-edit',
        order: 2
    },
    {
        label: 'View Policy',
        condition: 'show_view_policy',
        action: 'view_policy',
        icon: 'icon-svg icon-view-policy',
        order: 8
    },
    {
        label: 'View Policy Details',
        condition: 'show_view_policy_details',
        action: 'view_policy_details',
        icon: 'icon-svg icon-view-policy',
        order: 7
    },
    {
        label: 'View All Documents',
        condition: 'show_view_all_documents',
        action: 'view_all_documents',
        icon: 'icon-svg icon-view-all-documents',
        order: 9
    },
    {
        label: 'Transfer Sale',
        condition: 'show_transfer_sale',
        action: 'transfer_sale',
        icon: 'icon-svg icon-transfer-sale',
        order: 10
    },
    {
        label: 'Change Underwriter',
        condition: 'show_change_underwriter',
        action: 'change_underwriter',
        icon: 'icon-svg icon-change-underwriter',
        order: 11
    },
    {
        label: 'Cancel Policy',
        condition: 'show_cancel_policy',
        action: 'cancel_policy',
        icon: 'icon-svg icon-cancel-policy',
        order: 12
    },
    {
        label: 'Refunds',
        condition: 'show_refunds',
        action: 'refunds',
        icon: 'icon-svg icon-refunds',
        order: 13
    },
    {
        label: 'Initiate GE',
        condition: 'show_initiate_ge',
        action: 'initiate_ge',
        icon: 'icon-svg icon-amend-invoice',
        order: 14
    },
    {
        label: 'View Receipt',
        condition: 'show_view_receipt',
        action: 'view_receipt',
        icon: 'icon-svg icon-view-receipt',
        order: 15
    },
    {
        label: 'View Cancellation Documents',
        condition: 'show_view_cancellationDocuments',
        action: 'view_cancellation_documents',
        icon: 'icon-svg icon-view-cancellation-documents',
        order: 16
    },

    // invoice
    {
        label: 'Make Invoice',
        condition: 'show_amend_invoice',
        action: 'amend_invoice',
        icon: 'icon-svg icon-amend-invoice',
        order: 1
    },
    {
        label: 'View Receipt',
        condition: 'show_invoice_view_receipt',
        action: 'view_receipt',
        icon: 'icon-svg icon-view-receipt',
        order: 2
    },
    {
        label: 'Upload Receipt',
        condition: 'show_upload_receipt',
        action: 'upload_receipt',
        icon: 'icon-svg icon-upload-receipt',
        order: 3
    },
    {
        label: 'View Installments',
        condition: 'show_installments',
        action: 'installments',
        icon: 'icon-svg icon-view-receipt',
        order: 4
    },
    {
        label: 'Invoice Details',
        condition: 'show_invoice_details',
        action: 'invoice_details',
        icon: 'icon-svg icon-view-receipt',
        order: 5
    },


]