import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

// import { ElNotification } from "element-plus";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description To check if the error captured in catch is undefined or not to avoiding
   * @param err 
   * @returns boolean
   */
  public static errUndefinedCheck = (err) => (err && err.response && err.response.status !== undefined);

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;///process.env.VUE_APP_API_URL;//EnvService.getApiUrl();//"http://api.bai.test/";//https://api-ae.new.baidev.com/
  }
 
  // https://api-ae.new.baidev.com
  // http://api.bai.test/
  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }

  public static setFileHeader(): void {
    ApiService.vueInstance.axios.defaults.headers[
      "Content-Type"
    ] = `multipart/form-data`;
  }
  
  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
  ): Promise<AxiosResponse> {

    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = window.localStorage.getItem('id_token')
        if (token && (token != undefined || token != null)) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    ApiService.vueInstance.axios.interceptors.response.use((res) => {
        return res;
    }, ((err) => {
        return Promise.reject(err);
    })
    )
    // /${slug}
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {

    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = window.localStorage.getItem('id_token')
        const uuid = window.localStorage.getItem('uid')
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
       
        config.headers['MISK-PRODUCT'] = router.currentRoute.value.meta.product
        config.headers['Content-Type'] = 'application/json';
        config.headers['uid'] = uuid
        return config
      },
      error => {
        if(ApiService.errUndefinedCheck(error)){
          return Promise.reject(error)
        }
      }
    )

    // ApiService.vueInstance.axios.interceptors.response.use((res) => {
      
    //     // path save to local storage if path !=== sign in
    //     // path will be use to return to original state once token expired
    //     if(router.currentRoute.value.fullPath !== '/sign-in' && router.currentRoute.value.fullPath !== '/') {
    //         window.localStorage.setItem('currentRoute', JSON.stringify(router.currentRoute.value.fullPath));
    //     }
    //     return res;
    // }, ((err) => {
    //     return Promise.reject(err);
    // })
    // )

    ApiService.vueInstance.axios.interceptors.response.use(function (response) {
      if(!['/sign-in', 'user/calendar', '/'].includes(router.currentRoute.value.fullPath)) {
                // const currentRoute = window.localStorage.getItem('currentRoute');
                // if(currentRoute !== JSON.stringify(router.currentRoute.value.fullPath)) {
                //   ElNotification.closeAll();
                // }
                window.localStorage.setItem('currentRoute', JSON.stringify(router.currentRoute.value.fullPath));
            }
            return response;
    }, async function (error) {

      if((typeof(error.response) !== 'undefined') && error.response.status === 403) {
        
        // const uid = window.localStorage.getItem('uid');
        // const refresh_token = window.localStorage.getItem('refresh_token');
        // const payload = {
        //   refresh_token,
        //   uid
        // }
        // const response = await store.dispatch(Actions.CHECK_TOKEN, payload)

        // store.commit(Mutations.PURGE_AUTH_SIGNIN)
        // window.location.reload();
        // setTimeout(() => {
        //   router.push('/sign-in');
        // }, 1000);
      }
      
      return Promise.reject(error);
    });

    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }
  
  public static post_form(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {

    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = window.localStorage.getItem('id_token')
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
        config.headers['Content-Type'] = 'multipart/form-data';
        return config
      },
      error => {
        if(ApiService.errUndefinedCheck(error)){
          return Promise.reject(error)
        }
      }
    )

    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  public static post_get(
    resource: string
  ): Promise<AxiosResponse> {

    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = window.localStorage.getItem('id_token')
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token
        }
        config.headers['Content-Type'] = 'application/json';
        return config
      },
      error => {
        if(ApiService.errUndefinedCheck(error)){
          return Promise.reject(error)
        }
      }
    )

    ApiService.vueInstance.axios.interceptors.response.use((res) => {
      
        // path save to local storage if path !=== sign in
        // path will be use to return to original state once token expired
        if(!['/sign-in', 'user/calendar', '/'].includes(router.currentRoute.value.fullPath)) {
            window.localStorage.setItem('currentRoute', JSON.stringify(router.currentRoute.value.fullPath));
        }
        return res;
    }, ((err) => {
      if(ApiService.errUndefinedCheck(err)){
        return Promise.reject(err);
      }
    })
    )

    return ApiService.vueInstance.axios.post(`${resource}`);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
