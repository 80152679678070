<template>
    <!-- <vc-sockets :channel="`invoice-generated-car-${driver_id}`" listen="MakeinvoiceEvent" @action="redirectCustomer" /> -->
      <vc-modal-payment-warning ref="payment_warning" title="" message="Invoice Generation In Progress. Do not Refresh" />
    <div 
        v-loading="showPageLoader" 
        element-loading-background="rgba(122, 122, 122, 0.4)"
        element-loading-text="Please wait while loading the information"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1;"
    >
        <div class="card" v-if="!componentLoad">
            <div class="card-body">
                Please wait while fetching the data.....
            </div>
        </div>
        <div class="card make-invoice" v-else>
            <div class="card-header">
                
                <vc-modal-reusable  
                    :closed-modal="false"
                    :show-close="false"
                    :title="invoiceDetails.title"
                    :message="invoiceDetails.message"  
                    :item="invoiceDetails"
                    :show-cancel-button="invoiceDetails.showCancelButton"
                    show-icons
                    button-cancel-text="Cancel"
                    @submitCancel="sendEmailToIt"
                    @submitOk="redirectCustomer"
                    ref="reusable_invoice"
                    :button-okext="buttonOk"
                />
                <CustomerTitle />
            </div>
    
            <el-form
                ref="ruleFormRef"
                :model="customer"
                :rules="rules"
                label-position="top"
                v-if="customer"
            >
                
                    <addCustomerIndex 
                        ref="customerDetailsComponent" 
                        @validatePromo="validatePromoField" 
                        @removeValidation="clearValidate" 
                        :disable-all="route.name == 'critical-illness-customer-lead-make-invoice'" 
                        :disable-gender="false" 
                    />
               
                
                
            </el-form>
    
            <div class="card-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 text-center">
                            <button
                                class="btn btn-secondary me-3"
                                :disabled="disabledButton"
                                @click="goBack"
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                @click="addUserDetails(ruleFormRef)"
                                class="btn btn-info"
                                :disabled="loading || disabledButton"
                                :data-kt-indicator="loading ? 'on' : null"
                            >
                                <span v-if="loading" class="indicator-progress">
                                    Please wait...
                                    <span
                                    class="spinner-border spinner-border-sm align-middle ms-2"
                                    ></span>
                                </span>
                                <span v-else>
                                    <template v-if="['generate-manaual-quote', 'generate-driver-details-manaual-quote'].includes(route.name)">
                                        &nbsp;&nbsp;Generate &amp; Download&nbsp;&nbsp;
                                    </template>
                                    <template v-else>
                                        &nbsp;&nbsp;Save&nbsp;&nbsp;
                                    </template>
                                </span>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
    <script setup>
    /* eslint-disable */ 
    import { provide, ref, reactive, computed, watchEffect, onMounted, onBeforeMount } from 'vue'
    import { useFetchCustomer, useUpdateError, useClearPhoneNumber, useEditLeadDetails, useSaveCiMakeInvoice, useUpdateCustomerData } from '@/store/composable/Customer'
    import { useStore } from 'vuex'
    import { useRouter, useRoute } from 'vue-router'
    import Validations from '@/core/services/etc/Validations'
    import CustomerTitle from '@/components/NEW/Customer/Add/Title.vue'
    import { notif } from '@/store/stateless/store'
    import addCustomerIndex from '@/components/NEW/Customer/Add/Index.vue'
    
    const { title, route_name, driver_id, leadsAndTasks } = useFetchCustomer()
    const ruleFormRef = ref()
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    
    const customerDetailsComponent = ref()
    const loading = ref(false)
    const disabledButton = ref(false)
    const showPageLoader = ref(false)
    const hasDuplicateInvoice = ref(false)
    
    provide('customers', { title, route_name, driver_id, leadsAndTasks })
    
    // onMounted(() => {
    //     ruleFormRef.value.resetField()
    // })
    
    onBeforeMount(() => {
            window.addEventListener("beforeunload", preventNav)
    })
    
    function preventNav(event) {
        if(route_name && route_name === 'generate-invoice') {
            if (!loading.value) return
            event.preventDefault()
            event.returnValue = ""
        }
        
    }
    
    
    const customer = computed(() => {
        return store.state.Customer.customer
    })
    const componentLoad = computed(() => {
        return store.state.Customer.componentLoad
    })
    
    const buttonOk = ref('Yes')
    
    const invoiceDetails = reactive({
        title: '',
        message: '',
        isError: false,
        invoice_id: 0,
        paymentType: 0,
        whatsapp: '',
        customer_id: 0,
        module: 'make-invoice',
        showCancelButton: false,
        showEmailIcon: true,
        showWhatsappIcon: true,
    })
    
    watchEffect(() => {
        if(route_name != undefined) {
            // if(['customer-add-detail', 'customer-add-bike-detail'].includes(route_name?.toString())) useUpdateError('phone_number') 
            // if(['new-car-quote', 'add-lead-driver-details', 'edit-quote', 'edit-bike-quote', 'customer-add-new-bike', 'generate-manaual-quote', 'generate-driver-details-manaual-quote'].includes(route_name?.toString())) useUpdateError('car_year') 
            // if(route_name === 'add-driver-details') useUpdateError('dob') 
            if(route_name === 'critical-illness-customer-lead-make-invoice') useUpdateError('plan_id') 
    
            console.log('customer',customer.value)
            if(customer.value.has_invoice) {
                    invoiceDetails.title = 'Invoice already exists'
                    invoiceDetails.message = `<span>Quote Reference Number <b> ${customer.value.has_invoice.merchant_reference} </b></span>`
                    invoiceDetails.isError = false
                    invoiceDetails.invoice_id = customer.value.has_invoice.id
                    invoiceDetails.whatsapp = ''
                    invoiceDetails.showCancelButton = false
                    invoiceDetails.showEmailIcon = false
                    invoiceDetails.showWhatsappIcon = false
    
                    buttonOk.value = 'Yes'
    
                    setTimeout(() => {
                        reusable_invoice.value.open()
                    }, 200);
                }
    
                if (componentLoad.value && customer.value && !customer.value.has_invoice) {
                    setTimeout(() => {
                        if (customerDetailsComponent.value) customerDetailsComponent.value.focus()
                    }, 500)
                }
        }
    })
    
    const rules = reactive({
        name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
        email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
        phone_number: [{ validator: Validations.phone_number, trigger: ['blur', 'change'] }],
        lead_gender: [{ validator: Validations.lead_gender, trigger: ['blur', 'change'] }],
        lead_monthly_income: [{ validator: Validations.lead_monthly_income, trigger: ['blur', 'change'] }],
        // lead_is_smoke: [{ validator: Validations.lead_is_smoke, trigger: ['blur', 'change'] }],
        insured_name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
        insured_dob: [{ validator: Validations.insured_dob, trigger: ['blur', 'change'] }],
        insured_email: [{ validator: Validations.email, trigger: ['blur', 'change'] }],
        insured_personal_mobile_number_wo_country_code: [{ validator: Validations.phone_number, trigger: ['blur', 'change'] }],
        // insured_home_mobile_number_wo_country_code: [{ validator: Validations.insured_home_mobile_number_wo_country_code, trigger: ['blur', 'change'] }],
        insured_full_home_address: [{ validator: Validations.insured_full_home_address, trigger: ['blur', 'change'] }],
        insured_emirate_id_number: [{ validator: Validations.insured_emirate_id_number, trigger: ['blur', 'change'] }],
        insured_emirate_id_expiry: [{ validator: Validations.insured_emirate_id_expiry, trigger: ['blur', 'change'] }],
        insured_emirate_id_path: [{ validator: (rule, value, callback) => Validations.insured_emirate_id_path(rule, value, callback, customer.value.insured_emirate_path), trigger: ['blur', 'change'] }],
        // insured_has_beneficiary: [{ validator: Validations.insured_has_beneficiary, trigger: ['blur', 'change'] }],
        beneficiary_name: [{ validator: Validations.names, trigger: ['blur', 'change'] }],
        beneficiary_shares: [{ validator: Validations.beneficiary_shares, trigger: ['blur', 'change'] }],
        beneficiary_relationship: [{ validator: Validations.beneficiary_relationship, trigger: ['blur', 'change'] }],
        beneficiary_proof_type: [{ validator: Validations.beneficiary_proof_type, trigger: ['blur', 'change'] }],
        beneficiary_proof_number: [{ validator: Validations.beneficiary_proof_number, trigger: ['blur', 'change'] }],
        beneficiary_image_path: [{ validator: (rule, value, callback) => Validations.beneficiary_image_path(rule, value, callback, customer.value.beneficiary_path), trigger: ['blur', 'change'] }],

        // Invoice

        plan_id: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change'] }],
        sum_insured: [{ validator: Validations.sum_insured, trigger: ['blur', 'change'] }],
        term: [{ validator: Validations.term, trigger: ['blur', 'change'] }],
        payment_frequency: [{ validator: Validations.payment_frequency, trigger: ['blur', 'change'] }],
        no_of_payments: [{ validator: Validations.no_of_payments, trigger: ['blur', 'change'] }],
        // annual_premium: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change'] }],
        // policy_price: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change'] }],
        // invoice_amount: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change'] }],
        payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change'] }],
        order_description: [{ validator: Validations.order_description, trigger: ['blur', 'change'] }],
        reference_no: [{ validator: Validations.reference_no, trigger: ['blur', 'change'] }],
        payment_date: [{ validator: Validations.payment_date, trigger: ['blur', 'change'] }],

        
    })
    
    function goBack() {

        router.push({ name: 'critical-illness-customer-details', params: { customerId: customer.value.customer_id }, query: { lead_id: route.params.leadId } }).then(() => { router.go(0) })
    }
    
    function redirectIt (id, redirectType) {
          let path = ''
          if(redirectType === 'customerDetails') path = 'customer-details'
          if(redirectType === 'addCar') path = 'new-car-quote'
          if(redirectType === 'addBike') path = 'customer-add-new-bike'
          if(redirectType === 'addCar') {
            router.push({ name: path, params: { driverDetailsId: id }, query: {leadsAndTasks: 'true'}})
          } else {
            router.push({ name: path, params: { customerId: id }, query: {leadsAndTasks: 'true'}})
          }
          useClearPhoneNumber(customer.value)
        };
    
    const reusable_invoice = ref()
    const payment_warning = ref()
    function addUserDetails(formEl) {
    
            useUpdateError('')
            Validations.is_reference_no_exist = false
    
            if (!formEl) return
            formEl.validate(async (valid, fields) => {
                // loading.value = true
                if (valid) {
                    hasDuplicateInvoice.value = false
                    if (route.name == 'critical-illness-customer-lead-make-invoice') {
                        payment_warning.value.open(true)
                        showPageLoader.value = true
                        disabledButton.value = true
                        
                        const makeInvoice = prepareData(customer.value);

                        const response = await useSaveCiMakeInvoice(makeInvoice)
    
                        if (response.status < 299 || response.data.data.has_pending_invoice) {
                            invoiceDetails.customer_id = response.data.data.invoice.customer.id
                            invoiceDetails.paymentType = response.data.data.invoice.payment_type

                            // let message = `Payment url: ${response.data.data.invoice.payment_link}`
                            
                            invoiceDetails.title = response.data.data.has_pending_invoice ? 'Invoice already exists' : 'Invoice Generated'
                            invoiceDetails.message = response.data.data.has_pending_invoice ? `<span>Quote Reference Number <b> ${response.data.data.invoice.merchant_reference} </b></span>` : `Invoice - ${response.data.data.invoice.merchant_reference} generated sucessfully.`
                            invoiceDetails.isError = false
                            invoiceDetails.invoice_id = response.data.data.invoice.id
                            // invoiceDetails.whatsapp = `https://wa.me/${response.data.data.invoice.customer.whatsapp_number.replace('+', '')}?text=${message} -BuyAnyInsurance`
                            invoiceDetails.showCancelButton = false
                            invoiceDetails.showEmailIcon = false
                            invoiceDetails.showWhatsappIcon = false
                        } else {
                            let message = '<span class="text-danger">There was an issue in invoice generation. Kindly contact the technical team to resolve the issue</span>'
    
                            if(response.data.data.has_invoice_created) {
                                message = '<span class="text-danger">Something went wrong, You are trying to create a duplicate invoice</span>'
                                hasDuplicateInvoice.value = true
                            }
    
                            invoiceDetails.title = 'Invoice Error'
                            invoiceDetails.message = message
                            invoiceDetails.isError = true
                            invoiceDetails.invoice_id = 0
                            invoiceDetails.whatsapp = ''
                            invoiceDetails.showCancelButton = true
                            invoiceDetails.showEmailIcon = false
                            invoiceDetails.showWhatsappIcon = false
                            disabledButton.value = false
                        }

                        showPageLoader.value = false
                        payment_warning.value.open(false)
                        loading.value = false
    
                        if(response.data.data.reference_no) {
                            Validations.is_reference_no_exist = true
                            useUpdateError('reference_no');
                            formEl.validateField('reference_no')
                            customerDetailsComponent.value.focus()
                            disabledButton.value = false
                        } else {
                            setTimeout(() => {
                                if(reusable_invoice.value)
                                reusable_invoice.value.open()
                            }, 200);
                        }
                        
                    } else {
                        const preparedData = prepareData(customer.value);

                        const response =  await useEditLeadDetails(preparedData)
                        if(response.status < 299) {
                            router.push({name : 'critical-illness-customer-details', params: {customerId: customer.value.customer_id}, query: {lead_id: customer.value.lead_id} })
                            .then(() => {router.go(0)})
                        }
                        // let customerId = toResponse ? response.data.data.customer_id : customer.value.customer_id
                        // if (response.status < 299) {
                        //     if (['generate-manaual-quote', 'generate-driver-details-manaual-quote'].includes(route.name?.toString())) {
                        //         window.open(response.data.data.quotation.file_path)
                        //     }
    
                        //     if(response.data && response.data.data && response.data.data.lead_driver_details && response.data.data.lead_driver_details.lead_id) await router.push({ name: "customer-details", params: { customerId }, query: {lead_id: response.data.data.lead_driver_details.lead_id} }).then(() => router.go(0))
                        //     else if(customer.value.lead_id) await router.push({ name: "customer-details", params: { customerId }, query: {lead_id: customer.value.lead_id} }).then(() => router.go(0))
                        //     else await router.push({ name: "customer-details", params: { customerId } }).then(() => router.go(0))
                            
                        // }
                    }
                    loading.value = false
                }
                else {
                    loading.value = false
                    const firstError = Object.keys(fields)[0]
                    useUpdateError(firstError);
                    customerDetailsComponent.value.focus()
                }
            })
        }
    
    async function redirectCustomer() {
        if (route.name == 'critical-illness-customer-lead-make-invoice')
        await useUpdateCustomerData({ linkType: 'make-invoice-event', driver_id, type: 'car' })
    
        if(customer.value.lead_id) await router.push({ name: "critical-illness-customer-details", params: { customerId: customer.value.customer_id }, query: {lead_id: customer.value.lead_id} }).then(() => router.go(0))
        else router.push({ name: "critical-illness-customer-details", params: { customerId: customer.value.customer_id } })
    }
    
    async function sendEmailToIt() {
        if(invoiceDetails.isError) {
            const response = await useUpdateCustomerData({ linkType: 'send-email-it', customer_id: customer.value.customer_id, has_duplicate_invoice: hasDuplicateInvoice.value })
            if(response.status < 299) {
                notif.simple('', 'success', 'Email has been sent successfully!');
            } else {
                notif.simple('', 'warning', 'Email has not been sent!');
            }
        }
    
        redirectCustomer()
    }
    
    function clearValidate(value) {
        ruleFormRef.value.clearValidate(value)
    }
    
    function prepareData(customer) {
    
            const form = new FormData()
    
            for (const key in customer) {
                if(['split_payments'].includes(key))
                    form.set("split_payments", encodeURIComponent(JSON.stringify(customer[key])));

                // if(['name', 'insured_name', 'beneficiary_name'].includes(key))
                //     form.set(key, encodeURIComponent(customer[key]))
                else
                    form.set(key, customer[key]);


            }
            return form
        }
    
    function validatePromoField(load){
        showPageLoader.value = load == 1;
        ruleFormRef.value.validateField('promo_code')
    }
    
    </script>
    
    <style>
    .el-form--label-top .el-form-item__label {
        padding: 0 !important
    }
    
      .el-form-item.is-error .el-input__inner {
        border-color: #D3D3D3 !important;
      }
    
      .el-radio__input.is-checked .el-radio__inner {
        border-color: #003367;
        background: #003367;
        width: 18px;
        height: 18px;
      }
    
      .el-radio__input .el-radio__inner {
        width: 20px;
        height: 20px;
      }
    
      .el-radio__input.is-checked+.el-radio__label {
        color: #003367;
      }
    
    </style>