<template>
    <div class="card card-bordered mt-5">
        <div class="card-header ribbon" style="min-height: 10px !important">
            <div class="card-title"> Update Documents </div>
            <div>
                <el-button
                    @click="downloadZip"
                    class="btn btn-download-all mt-5 mb-5"
                >
                <i class="icon-svg icon-download" /> All
                </el-button>
            </div>
        </div>
        <div class="card-body">
            <vc-tables
                :data="showAllDocuments" 
                :headers="headers" 
                is-document
                :show-upload="showView && editedPolicy.status <= 4 && (!isQcaRoleOrEditPolicyDetails)"
                show-delete-action
                :dropzone_lead_id="editedPolicy.lead_id"
                :dropzone_policy_id="editedPolicy.policy_id"
                :dropzone_invoice_id="editedPolicy.parent_invoice_id"
                :quote_ref_no="editedPolicy.parent_merchant_reference"
                dropzone_categories="standard"
                is-sr
                @refresh="getTempDocuments"
                @deleteDocument="deleteDocument"
                :disableDocumentType="isQcaRoleOrEditPolicyDetails"
            />
            
        </div>
    </div>
</template>

<script  setup>
import { computed, defineEmits, onMounted } from 'vue'
import { useStore } from 'vuex'
import { all_documents } from '@/core/utils/Tables'
import { useRoleBasedConditions } from '@/store/composable/User'
 import { useRoute } from 'vue-router'
 
const store = useStore()

const { showDeleteAction, showView } = useRoleBasedConditions()
const emit = defineEmits(['refresh', 'deleteDocument'])

const route = useRoute()

const policy = computed(() => {
    return store.state.Policy.policy
})

const editedPolicy = computed(() => {
        return store.state.Policy.editPolicy
    })


const deleteDocument = (type) => {
    emit('deleteDocument', type)
}

const showAllDocuments = computed(() => {
    return store.state.Policy.mainDocument
})

const headers = computed(() => {
    return all_documents.filter(x => x.value !== 'quote_ref_no')
})

async function getTempDocuments(data) {
    emit('refresh', data)
}


async function downloadZip() {
    // const policy = completedDocuments.value.length > 0 ? completedDocuments.value.filter(x => x.document_type == 'verification').map(x => { return x.id }) : []
    // const temp = tempDocuments.value.length > 0 ? tempDocuments.value.filter(x => x.document_type == 'verification').map(x => { return x.id }) : []
    // try {
    //     const response = await useUpdateCustomerData({linkType: 'document-download', policy, temp })
    //     if(response.status < 299 && response.data.data.file) return window.open(response.data.data.file)
    // } catch (error) {
    //     return error
    // }
}


const isQcaRoleOrEditPolicyDetails = computed(() => {
    // return policy.value.item?.is_qca_role_and_view_or_edit_policy_details
    return false
})

</script>
