import Dashboard from "@/views/critical-illness/Dashboard.vue"
import LeadsAndTasks from "@/views/critical-illness/LeadsAndTasks.vue"
import CustomerDetails from "@/views/critical-illness/CustomerDetails.vue"
import LeadDetails from "@/views/critical-illness/LeadDetails.vue"
import InvoiceList from "@/views/critical-illness/InvoiceList.vue"
import AmendInvoice from "@/views/critical-illness/AmendInvoice.vue"
import PolicyDetails from "@/views/critical-illness/PolicyDetails.vue"
import UpdatePolicy from "@/views/critical-illness/UpdatePolicy.vue"

export const critical_illness = [
    {
      path: "dashboard",
      name: "critical-illness-dashboard",
      component: Dashboard,
    },
    {
      path: "customers/list",
      name: "critical-illness-customer-list",
      component: LeadsAndTasks,
    },
    {
      path: "customer/details/:customerId",
      name: "critical-illness-customer-details",
      component: CustomerDetails,
    },
    {
      path: "customer/lead/edit/:leadId",
      name: "critical-illness-customer-edit-lead-details",
      component: LeadDetails,
    },
    {
      path: "customer/lead/make-invoice/:leadId",
      name: "critical-illness-customer-lead-make-invoice",
      component: LeadDetails,
    },
    {
      path: "customer/lead/make-amend-invoice/:invoiceId",
      name: "critical-illness-customer-lead-make-amend-invoice",
      component: AmendInvoice,
    },
    {
      path: "/critical-illness/invoice/list",
      name: "critical-illness-approved-invoice",
      component: InvoiceList,
    },
    {
      path: "/critical-illness/invoice/approval",
      name: "critical-illness-approval-invoice",
      component: InvoiceList,
    },

    {
      path: "/critical-illness/invoice/decline",
      name: "critical-illness-decline-invoice",
      component: InvoiceList,
    },

    {
      path: "/critical-illness/invoice/installments",
      name: "critical-illness-invoice-installments",
      component: InvoiceList,
    },

    {
      path: "/critical-illness/policy/assigned",
      name: "critical-illness-policy-assigned",
      component: PolicyDetails,
    },

    {
      path: "/critical-illness/policy/completed",
      name: "critical-illness-policy-completed",
      component: PolicyDetails,
    },

    {
      path: "/critical-illness/policy/update/:policy_id",
      name: "critical-illness-policy-update",
      component: UpdatePolicy,
    },
    

]