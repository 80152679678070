<template>
    <el-input
        id="customer-original-price"
        v-model="amount"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
        maxlength="10"
        ref="inputRef"
        @blur="correctDecimal"
        @focus="removeComma"
        @input="updateModel"
        @keydown="handleKey"
        :disabled="disabled"
    />
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, watch, ref, onMounted, watchEffect } from 'vue'

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
        default: 0
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false
    },
    maxValue: {
        type: Number,
        required: false,
    },
    minValue: {
        type: Number,
        required: false,
    }
})

const amount = ref("0")
const isFocus = ref(false)
const inputRef = ref()
const focus = () => {
    setTimeout(() => {
    inputRef.value.focus()
    }, 250);
}

const emit = defineEmits(['update:modelValue', 'change', 'press'])

onMounted(() => {
    if(typeof props.modelValue == 'number') {
        amount.value = props.modelValue
        if(amount.value) amount.value = formatCurrency(amount.value)
    }

    if((typeof props.modelValue == 'string' || typeof props.modelValue == 'number') && (props.modelValue == 'NaN' || isNaN(props.modelValue))) {
        amount.value = "0"
        if(amount.value) amount.value = formatCurrency(amount.value)

        let fixedValue = useFixFigure(0)
        emit("update:modelValue", fixedValue)
    }


    if((typeof props.modelValue == 'string' || typeof props.modelValue == 'number') && props.modelValue != 'NaN') {
        amount.value = props.modelValue.toString().replaceAll(',', '')
        if(amount.value) amount.value = formatCurrency(amount.value)
    }
})

watchEffect(() => {
    if(!isFocus.value) amount.value = formatCurrency(props.modelValue)

})

watch(() => props.modelValue, newValue => {
    if(newValue == '' || newValue == null) amount.value = "0"

    
    if(newValue == 'NaN') {
        amount.value = "0"
        let fixedValue = useFixFigure(0)
        emit("update:modelValue", fixedValue)
    }

    if(typeof newValue == 'number' && isFocus.value === false) {
        amount.value = newValue
        if(amount.value) amount.value = formatCurrency(amount.value)
    }
})

const isDecimals = ref(false)
function handleKey(event) {
    isDecimals.value = event.keyCode
}

function updateModel() {
    if(amount.value == "") amount.value = "0"
    
    fixValue([8, 110].includes(isDecimals.value))
    const fixedValue = useFixFigure(amount.value)
    emit("update:modelValue", fixedValue)
    emit('press', fixedValue)
}

function removeComma() {
    isFocus.value = true
    let currentValue = amount.value
    if(!currentValue) currentValue = props.modelValue
    if(amount.value == 'NaN') amount.value = 0
    if(typeof currentValue == 'number') amount.value = currentValue
    setTimeout(() => {
        inputRef.value.select()
    }, 100);
}

function fixValue(isDecimal = false) {
    let formatedValue = formatCurrency(amount.value, isDecimal)
    if(formatedValue == 'NaN') formatedValue = 0

    amount.value = formatedValue
}

function correctDecimal() {
    
    const currentAmount = amount.value.replaceAll(',', '')
    if(props.maxValue && Number(currentAmount) > Number(props.maxValue) ) {
        const fixedValue = useFixFigure(props.maxValue)
        emit("update:modelValue", fixedValue)
    }

    
    if(props.minValue && Number(currentAmount) <= Number(props.minValue)) {
        const fixedValue = useFixFigure(props.minValue)
        emit("update:modelValue", fixedValue)
    }

    isFocus.value = false
    if(amount.value == '') {
        amount.value = '0'
        emit("update:modelValue", 0)
    }

    if(amount.value && !amount.value.endsWith('.') && !amount.value.includes('.')) {
        amount.value = amount.value + '.00'
    }

    if(amount.value && amount.value.endsWith('.')) {
        amount.value = amount.value + '00'
    }
}

function formatCurrency(e, isDecimal = false) {
    let numStr = e.toString();
    if(numStr == '0.00')
        return numStr = '0'

    if(numStr.length >= 9 && !numStr.includes('.')) {
        const toSlice = numStr.length == 8 ? -1 : -2
        numStr = numStr.slice(0, toSlice) + '.' + numStr.slice(toSlice)
    }
    
    if(numStr.length > 7 && !isDecimal && isFocus.value) {
        if(!numStr.includes('.')) {
            numStr = `${numStr.slice(0, 7)}.${numStr.slice(7)}`
            numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return numStr
        }
    }

    if (numStr.length > 8 && !isDecimal) {
        if (!numStr.includes('.')) {
            numStr += '.'; 
        }
    }
    
    if ((numStr.match(/\./g) || []).length > 1) {
        return numStr = numStr.slice(0, numStr.length - 1)
    }

    if (isDecimal) return numStr

    if (numStr.startsWith('0') && numStr.length > 1) {
        numStr = numStr.replace(/^0+/, ''); 
    }

    if(numStr.includes('.') && !isDecimal) {
        let [wholePart, decimalPart] = numStr.split('.');
    
        // Limit decimal part to 2 digits
        decimalPart = decimalPart.slice(0, 2);

        numStr = wholePart + (decimalPart.length > 0 ? '.' + decimalPart : '.');
    }
    numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return numStr;
}

function useFixFigure(value) {
    if(value) {
        let figures = parseFloat(value.toString().replaceAll(',', ''))
        return figures
    }
}


defineExpose({ focus })

</script>
