export const cancelAndRefund = [
    {
        label: 'Cancel Policy',
        icon: 'icon-svg icon-cancel-policy',
        title: 'Cancel Policy Request',
        cn: 'btn-active-color-primary cursor-pointer me-10',
        action: 'cancel-policy'
    },
    {
        label: 'Refunds',
        icon: 'icon-svg icon-refunds',
        title: 'Refund Policy Request',
        cn: 'btn-active-color-primary cursor-pointer me-10',
        action: 'refund-policy'
    },
]

export const cancellation_types = [
    {
        label: 'Full Cancellation',
        value: 'full-cancellation'
    },
    {
        label: 'Partial Cancellation',
        value: 'partial-cancellation'
    },
]

export const refund_types = [
    {
        label: 'Full Refund',
        value: 'full-refund',
        disabled: 'isCompleted'
    },
    {
        label: 'Partial Refund',
        value: 'partial-refund'
    },
    {
        label: 'Commision Paid',
        value: 'commision-paid'
    },
]

export const sendMails = [
    {
        label: 'Send Email To Customer',
        id: 'customer',
        action: 'email_customer'
    },
    {
        label: 'Send Email To Broker',
        id: 'almanara',
        action: 'email_broker'
    },
]

export const insurancePayments = [
    {
        value: "", 
        label: "Please Select Insurance Payment", 
        isDisabled: true
      },
      {
        value: 1, 
        label: "Own Account", 
        isDisabled: false
      },
      {
        value: 2, 
        label: "Payment Link Customer", 
        isDisabled: false
      },
      {
        value: 3, 
        label: "Payment Link BAI", 
        isDisabled: false
      },
      {
        value: 4, 
        label: "Intransact", 
        isDisabled: false
      },
]

export const entityTypes = [
    {
        label: 'Corporate',
        value: 1
    },
    {
        label: 'Individual',
        value: 2
    },
]

export const mainHeaders = [
    // {
    //     label: 'Quote Ref. No.',
    //     size: 2,
    //     cn: 'form-label'
    // },
    // {
    //     label: 'Invoice <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
    //     size: 2,
    //     cn: 'form-label'
    // },
    {
        label: 'CN <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
        size: 2,
        cn: 'form-label required'
    },
    {
        label: 'CN No.',
        size: 3,
        cn: 'form-label required'
    },
    {
        label: 'DN <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
        size: 2,
        cn: 'form-label required'
    },
    {
        label: 'DN No.',
        size: 3,
        cn: 'form-label required'
    },
    {
        label: 'Tax Inv. Dt.',
        size: 2,
        cn: 'form-label required'
    },
    // {
    //     label: 'Tax Dt.',
    //     size: 1,
    //     cn: 'form-label required'
    // },
]

export const initiates = [
    {
        label: 'Quote Ref. No.',
        value: 'quote_ref_no'
    },
    {
        label: 'Lead Details',
        value: 'lead_details'
    },
    {
        label: 'Status  <span class="fw-light text-gray-700 fs-8">(Policy Status)</span>',
        value: 'status'
    },
    {
        label: 'Policy Price <span class="fw-light text-gray-700 fs-8">(VAT) (CURRENCY)</span>',
        label_without_vat: 'Policy Price <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
        value: 'policy_price',
        value_without_vat: 'policy_price_without_vat',
        is_vat: true
    },
    {
        label: 'Invoice Amount <span class="fw-light text-gray-700 fs-8"> (CURRENCY)</span>',
        label_without_vat: 'Invoice Amount <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
        value: 'invoice_amount_without_vat',
        value_without_vat: 'invoice_amount_without_vat',
        is_vat: true
    },
    {
        label: 'Transaction Type',
        value: 'transaction_type'
    },
    {
        label: 'Previously Refunded',
        value: 'previously_refunded'
    }
]

export const cancelRequest = [
    {
        label: 'Cancellation Reason',
        value: 'cancellation_reason'
    },
    {
        label: 'Agent Notes',
        value: 'agent_notes'
    }
]

export const cancelApprove = [
    {
        label: 'Cancellation Reason',
        value: 'cancellation_reason'
    },
    {
        label: 'Agent Notes',
        value: 'agent_notes'
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_text'
    },
    {
        label: 'Underwriter Notes',
        value: 'underwriter_cancellation_notes'
    }
]

export const refundApprove = [
    {
        label: 'Refund Reason',
        value: 'refund_reason'
    },
    {
        label: 'Agents Refund Notes',
        value: 'refund_notes'
    },
    {
        label: 'Previously Refunded',
        value: 'previously_refunded'
    }
]

export const cancelStatus = [
    {
        label: 'For Cancellation Approval',
        value: 6
    },
    {
        label: 'Approved',
        value: 8
    },
    {
        label: 'Completed',
        value:7
    },
    {
        label: 'Declined',
        value: 9
    },
    {
        label: 'Return to underwriter',
        value: 10
    },
]

export const refundStatus = [
    {
        label: 'For Refund Approval',
        value: 1
    },
    {
        label: 'Approved',
        value: 3
    },
    {
        label: 'Completed',
        value: 2
    },
    {
        label: 'Declined',
        value: 4
    },
]

export const updateData = [
    {
        label: 'Quote Ref. No.',
        size: 2,
        cn: 'form-label'
    },
    {
        label: 'Invoice <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
        size: 2,
        cn: 'form-label'
    },
    {
        label: 'CN <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
        size: 1,
        cn: 'form-label required'
    },
    {
        label: 'CN No.',
        size: 1,
        cn: 'form-label required'
    },
    {
        label: 'DN <span class="fw-light text-gray-700 fs-8">(CURRENCY)</span>',
        size: 1,
        cn: 'form-label required'
    },
    {
        label: 'DN No.',
        size: 1,
        cn: 'form-label required'
    },
    {
        label: 'Endorse No.',
        size: 1,
        cn: 'form-label required'
    },
    {
        label: 'Payment Date',
        size: 2,
        value: 'form-label required'
    },
    {
        label: 'Ref. No.',
        size: 1,
        value: 'form-label required'
    }
]

export const requiredDocument = [1,2,3,4,5,6,7,8,9]
export const requiredDocumentNC = [1,2,3,4,5,6,7,8,9,21]
export const requiredCancellationDoc = [19, 18, 17]
export const requiredEitherCancellationDoc = [14, 15, 16]

export const updatePolicyDetailsProp = ['customer_name', 'emirates_id', 'policy_number', 'policy_start_date', 'chassis_number', 'entity_type']

export const actionsConditions = ['show_update_data', 'show_edit_policy_details', 'show_edit_refund_details', 'show_view_refund_details', 'show_edit_cancellation_details', 'show_view_cancellation_details', 'show_view_policy', 'show_view_policy_details', 'show_view_all_documents', 'show_transfer_sale', 'show_change_underwriter', 'show_cancel_policy', 'show_refunds', 'show_initiate_ge', 'show_view_receipt', 'show_view_cancellationDocuments']