<template>
    <div class="card card-bordered mt-5">
        <div class="card-header ribbon" style="min-height: 10px !important">
            <div class="align-self-end card-title"> Completed Invoices </div>
            
        </div>
        <div class="card-body card-custom-spacing-tblf-10">
            <div class="table-custom">
                <vc-tables
                    :data="store.state.Policy.completedInvoices"
                    :headers="store.state.Policy.completedInvoiceTable"
                    :show-actions="false"
                    is-sr
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';

const store = useStore();

</script>