import Dashboard from "@/views/Dashboard.vue"
import LeadsTasks from "@/views/NEW/LeadsTasks.vue"
import AddCustomer from "@/views/NEW/AddCustomer.vue"
import CustomerDetails from "@/views/NEW/CustomerDetails.vue"
import InvoiceList from "@/views/car/invoice/InvoiceList.vue"
import Policy from "@/views/NEW/Policy.vue"
import NotificationList from "@/views/car/notifications/NotificationList.vue"
import AccountMaster from "@/views/reports/NEW/AccountMaster.vue"
import LeadBySource from "@/views/reports/NEW/LeadBySource.vue"
import AdminReport from "@/views/reports/NEW/AdminReport.vue"
import DashboardDetails from "@/views/reports/NEW/DashboardDetails.vue"
import ExpiredLeads from "@/views/reports/NEW/ExpiredLeads.vue"
import CustomersWithoutLead from "@/views/reports/NEW/CustomersWithoutLead.vue"
import ProductionReportPage from "@/views/reports/NEW/ProductionReportPage.vue"
import MarketingCampaigns from "@/views/NEW/MarketingCampaigns.vue"
import MarketingSpend from "@/views/NEW/MarketingSpend.vue"
import MarketingReport from "@/views/NEW/Reports/Views/MarketingReport.vue"
import RoundRobinListing from "@/views/NEW/User/Views/RoundRobinListing.vue"

export const travel = [
    {
      path: "dashboard",
      name: "dashboard",
      component: Dashboard,
      
    },
    {
      path: "admin-dashboard",
      name: "admin-dashboard",
      component: Dashboard,
      
    },
    
    {
      path: "customers/list",
      name: "customer-list",
      component: LeadsTasks,
      
    },
    {
      path: "customers/add-detail",
      name: "customer-add-detail",
      component: AddCustomer,
      
    },
    {
      path: "customer/details/:customerId",
      name: "customer-details",
      component: CustomerDetails, // Part 2
      
    },

    /**
     * Routes for Invoices 
     **/
    {
      path: "lead/invoice/generate/:customerId/:driverDetailsId",
      name: "generate-invoice",
      component: AddCustomer,
      
    },
    {
      path: "invoice/list",
      name: "invoice-list",
      component: InvoiceList,
      
    },
    /**
     * Routes for Policies 
     **/
    {
      path: "policy/assigned",
      name: "policy-assigned",
      component: Policy,
      
    },
    {
      path: "policy/completed",
      name: "policy-completed",
      component: Policy,
      
    },
    
    {
      path: "notification/list",
      name: "notification-list",
      component: NotificationList,
      
    },
    
    {
      path: "report/account-master",
      name: "account-master", // route name
      component: AccountMaster,
      
    },
    
    {
      path: "report/lead-by-source",
      name: "lead-by-source", // route name
      component: LeadBySource,
      
    },
   
    {
      path: "report/admin-report",
      name: "admin-report", // route name
      component: AdminReport,
      
    },
    {
      path: "report/dashboard-details",
      name: "dashboard-details", // route name
      component: DashboardDetails,
      
    },
    
    {
      path: "report/expired-leads",
      name: "expired-leads", // route name
      component: ExpiredLeads,
      
    },
    {
      path: "report/customers-without-lead",
      name: "customers-without-lead", // route name
      component: CustomersWithoutLead,
      
    },
    {
      path: "report/production-reports",
      name: "production-reports", // route name
      component: ProductionReportPage,
      
    },

    
    {
      path: "report/marketing-report",
      name: "marketing-report", // route name
      component: MarketingReport,
      
    },

    {
      path: "report/marketing-report-agent",
      name: "marketing-report-agent", // route name
      component: MarketingReport,
      
    },

    {
      path: "marketing/campaigns",
      name: "marketing-campaigns", // route name
      component: MarketingCampaigns,
      
    },
    {
      path: "marketing/spend",
      name: "marketing-spend", // route name
      component: MarketingSpend,
      
    },
    
    
    {
      path: "user/roundrobin-listing",
      name: "roundrobin-listing", // route name
      component: RoundRobinListing,
      
    },

  ]