import { reactive } from "vue";
import moment from "moment";
import useClipboard from "vue-clipboard3";

let { toClipboard } = useClipboard();
const common = reactive({
    getY(value) {
        const top = document.querySelector(`#${value}`);
        return top.getBoundingClientRect().y
    },

    exceptFutureDates() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const day = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
        const month = (currentDate.getMonth() + 1) > 9 ? (currentDate.getMonth() + 1) : `0${(currentDate.getMonth() + 1)}`;

        return `${year}-${month}-${day}`
    },

    makeFirstCharUpperCase(value) {
        if(!value)
            return '';
        let str   = value.toLowerCase()
        return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
    },

    stringReplace(item, key, value) {
        return item.replace(key, value)
    },

    disableToday() {
        const myDate = new Date()
        return moment(myDate).format('YYYY-MM-DD')
    },

    setDate(e) {
        const MF = moment(e, 'DD/MM/YYYY HH:mm').isValid()
        if(!MF) return moment(e).zone("+0400").format("DD/MM/YYYY HH:mm");
        else return e
    },

    formatCurrency(e) {
        const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
    
          return formatter.format(e);
    },
    capitalizeFirstLetter([ first='', ...rest ]) {
        return [ first.toUpperCase(), ...rest ].join('');
    },
    leadStatus(lead_status_id) {
        // 1 NEW
        // 2 QUICK LEAD
        // 3 PENDING
        // 4 DEAL
        // 5 LEAD RENEWAL
        // 6 LOST LEAD
        // 7 LOST LEAD RENEWAL
        // 8 LEAD RENEWAL LOST
        // 9 DEAL TRANSFERRED
        // 10 SYS QUICK LEAD
        // 11 FALSE LEAD x
        // 12 CANCELLED
        // 13 INTRATRANSACT
        // 14 CLOSED
        switch(lead_status_id) {
            case 1:
                return 'color: #000; background-color: #FFDDDD !important'; 
            case 2:
                return 'color: #000; background-color: #E79DCE !important'; 
            case 3:
                return 'color: #000; background-color: #FFB822 !important'; 
            case 4:
                return 'color: #fff !important; background-color: #34BFA3 !important'; 
            case 5:
                return 'color: #000; background-color: #9AA4EA !important'; 
            case 6:
                return 'color: #fff !important; background-color: #F890A1 !important'; 
            case 7:
                return 'color: #fff !important; background-color: #2162F6 !important'; 
            case 8:
                return 'color: #fff !important; background-color: #F4516C !important'; 
            case 9:
            case 12:
            case 13:
            case 14:
                return 'color: #000; background-color: #DDDDDD !important';
            case 10:
                return 'color: #000; background-color: #BFBFBF !important';
            default: return 'color: #000; background-color: #5867DD !important';
    
        }
    },

    userType(e) {
        // 1 New - Not Contacted
        // 2 Return - Not Contacted
        // 3 Return - Follow Up
        // 4 Customer
        // 5 Lost Potential Customer
        // 6 Customer - Not Contacted
        // 7 Customer - Follow Up
        // 8 Partial Customer
        // 9 Lost Potential Customer - Not Contacted 
        // 10 Lost Potential Customer - Follow Up 
        // 11 Lost Customer
        // 12 New - Follow Up
        // 13 Partial Customer - Not Contacted 
        // 14 Partial Customer - Follow Up 
        // 15 Lost Customer - Not Contacted 
        // 16 Lost Customer - Follow Up 
        switch(e) {
            case 1: 
            case 6: return 'color: #000; background-color: #FFDDDD !important';
            case 2: return 'color: #000; background-color: #E79DCE !important';
            case 12: 
            case 7: return 'color: #000; background-color: #FFD881 !important';
            case 3: return 'color: #000; background-color: #FFB822 !important';
            case 4: return 'color: #fff; background-color: #34BFA3 !important';
            case 11: 
            case 15: 
            case 16: return 'color: #fff; background-color: #F4516C !important';
            case 5: 
            case 9: 
            case 10: return 'color: #fff; background-color: #F890A1 !important'; 
            case 8: 
            case 13: 
            case 14: return 'color: #000; background-color: #80DCCA !important'; 
            default: return 'color: #000; background-color: #5867DD !important';
        }
    },

    getTextWidth(text, font) {
        const canvas = document.createElement("canvas");
        if(canvas) {
            const context = canvas.getContext("2d");

            context.font = font || getComputedStyle(document.body).font;

            return context.measureText(text).width;
        }
    },

    isMobile() {
        if(screen.width <= 760) 
        return true

        return false
    },

    async copyLink(link) {
        try {
            await toClipboard(link);
        } catch (e) {
            return e
        }
    },

    setDateOnly(e) {
        const MF = moment(e, 'DD/MM/YYYY').isValid()
        if(!MF) return moment(e).zone("+0400").format("DD/MM/YYYY");
        else return e
    },

    setDBDate(e) {
        const MF = moment(e, 'DD/MM/YYYY', true)
        if(MF.isValid()) return MF.format('YYYY-MM-DD')
        
        return e
    },

    validateAmount(input){
        // Regular expression to match the pattern
        const regex = /^\d{1,9}(\.\d{1,2})?$/;
        // Test the input against the regex
        return regex.test(input);
    },
    
});

export {
    common
};