import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { useRoleBasedConditions, useUser } from '@/store/composable/User'
import { common } from '@/store/stateless/common'

const { role_id } = useUser()

interface keyVariable {
    [key: string] : string | number | undefined
}

@Module
export default class Policy extends VuexModule {
    policy = {
        insured_person: "",
        emirates_id: "",
        policy_plan: "",
        policy_number: "",
        policy_start_date: "",
        policy_end_date: "",
        passport: "",
        insurance_payment: "",
        entity_type: "",
        sum_insured: "",
        term: 0,
        status: 2,
    } as any
    prerequisite = {}
    tempDocuments = []
    compDocuments = []
    isDocLoaded = false
    amDocuments = []
    docTypes = []
    emailActivity = []
    editPolicy = {} as any
    completedInvoices = []
    completedInvoiceTable = []
    mainDocument = []
    installmentDocuments = []
    policyEmails = []
    editPolicyLoading = true
    editPolicySubmitted = false
    editPolicyTitle = ''
    initiateCancellation = {
        quote_ref_no: '',
        lead_details: '',
        status: '',
        policy_status: 0,
        policy_price: '',
        policy_price_without_vat: '',
        invoice_amount: '',
        invoice_amount_without_vat: '',
        transaction_type: '',
        policy_id: 0,
        lead_id: 0,
        cancellation_type: 0,
        cancellation_reason: 0,
        cancellation_note: '',
        documents: []
    }
    initiateRefund = {
        quote_ref_no: '',
        lead_details: '',
        status: '',
        policy_status: 0,
        policy_price: '',
        policy_price_without_vat: '',
        invoice_amount: '',
        invoice_amount_without_vat: '',
        transaction_type: '',
        policy_id: 0,
        lead_id: 0,
        previously_refunded: 0,
        refund_note: '',
        refund_payable_amount_agent: 0,
        refund_payable_amount_customer: 0,
        refund_payable_amount: 0,
        refund_reasons: '',
        refund_status: 1,
        refund_type: '',
        isCompleted: false
    }

    @Action
    async['GET_POLICY'](payload) {
        try {
            const response = await ApiService.post('/misk-skye/critical-illness/policies/get-policy-by-id', payload)
            this.context.commit('SET_POLICY', response.data.data)
            this.context.commit('SET_POLICY_MAIN_DOCUMENT', response.data.data.mainDocument)
            this.context.commit('SET_POLICY_INSTALLMENT_DOCUMENT', response.data.data.installmentDocuments)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['UPLOAD_AMEND_DOCUMENTS'](payload) {
        try {
            const response = await ApiService.post_form('/misk-skye/critical-illness/document/add-amend-document', payload)
            this.context.commit('SET_POLICY_INSTALLMENT_DOCUMENT', response.data.data.installmentDocuments)
            return response
        } catch (error) {
            return error
        }
    }

    @Mutation
    ['SET_POLICY']({customer, editPolicy, completed, completedTable, title, prerequisites, emails}) {
        this.policy = customer
        this.editPolicy = editPolicy
        this.completedInvoices = completed
        this.completedInvoiceTable = completedTable
        this.editPolicyTitle = title
        this.prerequisite = prerequisites
        this.policyEmails = emails
        this.editPolicyLoading = true
        this.editPolicySubmitted = false
    }

    @Mutation
    ['SET_POLICY_MAIN_DOCUMENT'](payload) {
        const selected = payload.filter(item => item.disabledType.includes(item.type)).map((x:any) => x.type)
        
        payload.forEach((item: any) => {
            item.types.forEach(type => {
                if (selected.includes(type.value)) {
                    type.disabled = true;
                }
            });
        })

        payload.sort((a, b) => a.sort_order - b.sort_order)

        this.mainDocument = payload
    }

    @Mutation
    ['SET_ADD_PENDING_DATA'] (data) {
        const value = {
            invoice_id: data.invoice_id,
            merchant_reference: data.merchant_reference,
            invoice_amount: Number(data.invoice_amount.replaceAll(',', '')),
            credit_amount: Number(data.credit_amount),
            credit_notes_no: data.credit_notes_no,
            debit_amount: Number(data.debit_amount),
            debit_notes_no: data.debit_notes_no,
            tax_invoice_date: data.tax_invoice_date,
        }

        if (!this.editPolicy.pending.some(obj => obj.invoice_id === value.invoice_id)) {
            this.editPolicy.pending.push(value);
        }
    }

    @Mutation
    ['SET_POLICY_INSTALLMENT_DOCUMENT'](payload) {
        this.installmentDocuments = payload
    }

    @Mutation
    ['SET_POLICY_PRELOADED'](payload) {
        this.prerequisite = payload
    }

    @Mutation
    ['SET_POLICY_SUBMITTED'](payload) {
        this.editPolicySubmitted = payload
    }

    @Mutation
    ['SET_POLICY_PRELOADED_TEMP_DOCUMENTS'](payload) {
        this.tempDocuments = payload
        this.isDocLoaded = true
    }

    @Mutation
    ['SET_POLICY_PRELOADED_COMP_DOCUMENTS'](payload) {
        this.compDocuments = payload
    }

    @Mutation
    ['SET_POLICY_PRELOADED_AM_DOCUMENTS'](payload) {
        this.amDocuments = payload
    }

    @Mutation
    ['SET_POLICY_PRELOADED_DOCUMENT_TYPES'](payload) {
        this.docTypes = payload
    }

    @Mutation
    ['SET_POLICY_PRELOADED_EMAIL_TYPES'](payload) {
        this.emailActivity = payload
    }

    @Mutation
    ['SET_EDIT_POLICY'](payload) {
        this.editPolicy = payload
    }

    @Mutation
    ['SET_INITIATE_CANCELLATION'](payload) {
        let data = payload
        if(!payload || Object.keys(data).length === 0) {
            data = {
                quote_ref_no: '',
                lead_details: '',
                status: '',
                policy_status: 0,
                policy_price: '',
                policy_price_without_vat: '',
                invoice_amount: '',
                invoice_amount_without_vat: '',
                transaction_type: '',
                policy_id: 0,
                lead_id: 0,
                cancellation_type: 0,
                cancellation_reason: 0,
                cancellation_note: '',
                documents: []
            }
        } 
        this.initiateCancellation = data
    }

    @Mutation
    ['SET_INITIATE_REFUND'](payload) {
        let data = payload
        if(!payload || Object.keys(data).length === 0) {
            data = {
                quote_ref_no: '',
                lead_details: '',
                status: '',
                policy_status: 0,
                policy_price: '',
                policy_price_without_vat: '',
                invoice_amount: '',
                invoice_amount_without_vat: '',
                transaction_type: '',
                policy_id: 0,
                lead_id: 0,
                previously_refunded: 0,
                refund_note: '',
                refund_payable_amount_agent: 0,
                refund_payable_amount_customer: 0,
                refund_payable_amount: 0,
                refund_reasons: '',
                refund_status: 1,
                refund_type: '',
                isCompleted: false
            }
        } 
        this.initiateRefund = data
    }

    get emailPolicyEmailActivity() {
        if(this.emailActivity.length > 0) {
            return this.emailActivity.map((x:any) => {
                return {
                    activity: x.activity_desc,
                    sent_by: x?.action_by?.name,
                    sent_on: x.created_at
                }
            })
        }
        return []
    }

    get getPolicy() {
        if(this.policy != undefined && this.policy.car_lead) {
            const { car_year, make, model, model_details, vehicle_type, is_vintage } = this.policy.car_lead
            const { name, email, phone_number, agent_details, id:customer_id } = this.policy.customer
            const { quote_ref_no, car_lead_driver_details } = this.policy.car_lead_quote
            const { approve_with_discrepancy } = this.policy.car_lead_invoice
            let title = 'view-policy-details'
            const details = `VEHICLE Details: ${car_year} | ${make} | ${model} | ${model_details}`
            if([1, 4, 14].includes(role_id) && this.policy.status != 10) title = 'edit-policy-details'
            
            let insurance_payment = this.policy.insurance_payment
            if([1, 4, 14].includes(this.policy?.car_lead_invoice?.payment_type) && !this.policy.insurance_payment) insurance_payment = 1
            if(this.policy?.car_lead_invoice?.payment_type === 3 && !this.policy.insurance_payment) insurance_payment = 2
            
            return {
                loading: true,
                item: {
                    policy_id: this.policy.id,
                    customer_id,
                    status: this.policy.status,
                    status_text: this.policy.status_text,
                    lead_id: this.policy.lead_id,
                    title,
                    is_vintage,
                    car_year,
                    with_discrepancy: this.policy?.car_lead_invoice?.is_discrepancy == 1,
                    details: details.replace('MISKINSURANCE', vehicle_type == 2 ? 'Bike' : 'Car'),
                    top: {
                        name,
                        email,
                        phone_number,
                        quote_ref: quote_ref_no,
                        agent: `Sales Agent: ${agent_details.name}`
                    },
                    policy_details: {
                        customer_name: this.policy.customer_name,
                        emirates_id: this.policy.emirates_id,
                        policy_plan: this.policy.car_insurance_policy?.policy_name,
                        policy_number: this.policy.policy_number,
                        policy_start_date: this.policy.policy_start_date,
                        policy_end_date: this.policy.policy_end_date,
                        chassis_number: this.policy.chassis_number,
                        insurance_payment,
                        entity_type: this.policy.entity_type,
                        approve_discrepancy: approve_with_discrepancy == 1,
                        is_amended: this.policy.is_amended === 1,
                        is_discrepancy: this.policy?.car_lead_invoice?.is_discrepancy == 1,
                        amount: this.policy?.car_lead_invoice ? this.policy.car_lead_invoice.amount : 0,
                        is_policy_start_date_enable: this.policy.is_policy_start_date_enable === 1,
                        invoices: invoices(this.policy.car_lead_invoices_edit_policy, approve_with_discrepancy == 1, this.policy.status),
                        disableInputs: ![4, 1, 14].includes(role_id) || this.policy.status > 4
                    },
                    expected_policy_start_date: car_lead_driver_details?.policy_start_date_format,
                    original_price: common.formatCurrency(this.policy.car_lead_invoice.original_price),
                    original_price_vat: common.formatCurrency(this.policy.car_lead_invoice.original_price_vat),
                    invoice_amount: common.formatCurrency(this.policy.car_lead_invoice.sale_price),
                    invoice_amount_vat: common.formatCurrency(this.policy.car_lead_invoice.vat),
                    payment_type: this.policy.car_lead_invoice.payment_type_text,
                    is_completed: this.policy.status == 4,
                    disabled_send_mail: role_id == 14,
                    total_refunds: totalRefunds(this.policy.car_refund_completed),
                    is_ocr: this.policy.is_amended === 0 && this.policy.car_insurance_policy?.insurance_provider?.ocr_enabled === 1,
                    car_insurance_policy_provider_id: this.policy.car_insurance_policy?.insurance_provider_id,
                    ...showCancelAndRefund(this.policy),
                }
            }
        }

        return {
            loading: false
        }
    }

    get showDocuments () {
        return this.isDocLoaded
    }

    get showAmDocuments () {
        if(this.amDocuments.length > 0) return true
        return false
    }

    get showEmail () {
        if(this.emailActivity.length > 0) return true
        return false
    }
}

function showCancelAndRefund(policy) {
    let showCancellation = false
    let showRefund = false

    if([1, 2, 9, 13].includes(role_id) && !policy.cancellation_initiated && policy.is_active && [4, 2].includes(policy.status) && !policy.car_refund_request_pending) {
        showCancellation = true
        showRefund = true
    }

    return { showCancellation, showRefund }
}

function invoices(invoices, approve_discrepancy = true, status = 0) {
    
    return invoices.map(x => {
        return {
            invoice_id: x.id,
            amend_type: x.amend_type,
            disabled: disableCheck(x, approve_discrepancy, status),
            amendment_start_date: x.amendment_start_date,
            amendment_end_date: x.amendment_end_date,
            merchant_reference: x.merchant_reference,
            amount: x.amount,
            credit_amount: x.credit_amount,
            credit_notes_no: x.credit_notes_no,
            debit_amount: x.debit_amount,
            debit_notes_no: x.debit_notes_no,
            endorsement_no: x.amend_type == '0' ? '-' : x.endorsement_no,
            endorsement_disabled: x.amend_type == '0',
            is_invoice_completed: x.is_policy_saved === 1
        }
    })
}

function totalRefunds(refunds) {
    if(refunds && refunds.length > 0) {
        return parseFloat(getTotal(refunds, 'refund_payable_amount_customer')) + parseFloat(getTotal(refunds, 'refund_payable_amount_agent')) 
    }
    return 0
}

function disableCheck(invoice, approve_discrepancy, status) {
    if(approve_discrepancy) {
        if((!invoice.is_policy_saved && invoice.amend_type == '0') || ![4, 1, 14].includes(role_id)) return true
        else if(invoice.amend_type == '0' && invoice.is_policy_saved == 1) return true
        else if(invoice.amend_type == '1') return true
        else if(status == 4) return true
        else false
    } else {
        if(invoice.is_policy_saved == 1 || ![4, 1, 14].includes(role_id)) return true
        else if([4, 10].includes(status)) return true
        else if(invoice.amend_type == '1') return true
        else return false
    }
}

function getTotal(arr, obj_name) {
    return arr.reduce((total, item) => {
        return total += parseFloat(item[obj_name])
    }, 0)
}