<template>
    <div class="uploadPolicy">
        <vc-sockets :channel="`ci-edit-policy-${route.params.policy_id}`" listen="GlobalNotificationToAppEvent" @action="usePolicyDetails(store, route.query && route.query.isView, route.params.policy_id)" />

            <div class="card" v-if="!loading">
                <div class="card-body">
                    Please wait while fetching the data.....
                </div>
            </div>
            <div class="card" v-else>
                <div class="card-header">
                    <h3 class="card-title">
                            <el-space :size="10" :spacer="spacer">
                            
                        <div
                            class="fs-6"
                            style="font-style: italic; font-weight: 200"
                            v-for="(top, index) in policy"
                            :key="index"
                            
                        >
                            <span class="text-gray-600"
                                :class="{'badge badge-light-info': index == 'quote_ref'}"
                            >
                                {{ top }}
                            </span>
                        </div>
                        </el-space>
                    </h3>
                    <!-- <div class="card-toolbar" v-if="policy.item.showCancellation && policy.item.showRefund">
                        <vc-modal-policy-cancel-initiate ref="initiateCancellation" @submit="callPolicy" />
                        <vc-modal-policy-refund-initiate ref="initiateRefund" @submit="callPolicy" />
                        <span
                            v-for="item in cancelAndRefund"
                            :key="item.action"
                            :class="item.cn"
                            @click="cr(item)"
                        >
                            <span v-if="policy.item.is_completed && item.with_condition">
                                <i :class="item.icon" />
                                {{ item.label }}
                            </span>
                            <span v-if="policy.item.show_refunds && !item.with_condition">
                                <i :class="item.icon" />
                                {{ item.label }}
                            </span>
                        </span>
                    </div> -->
                </div>
    
                <div class="card-body">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-sm-6 mt-3">
                                <!-- <span class="text-gray-600 fs-6 fw-bold"> {{ policy.item.details }} </span> -->
                            </div>
                            <div class="col-sm-6 mt-3 d-flex align-items-center justify-content-end" v-if="editedPolicy.status === 4">
                                <div class="d-flex align-items-center policy-details-actions">
                                    <el-checkbox v-for="mail in sendMails" :key="mail.action" v-model="mails[mail.action]" size="medium"> {{ mail.label }} </el-checkbox>
                                    <button
                                        @click="sendEmail"
                                        class="btn btn-danger mx-4 btn-send-mail"
                                        type="submit"
                                        >
                                        <span v-if="!emailLoading" class="indicator-label">
                                            <i class="far fa-paper-plane"></i>Send Email</span>
                                        <span
                                            v-else
                                        >
                                            Please wait...
                                            <span
                                            class="spinner-border spinner-border-sm align-middle ms-2"
                                            ></span>
                                        </span>
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator border-2 my-5"></div>
                <el-form
                    v-if="editedPolicy"
                    ref="ruleFormRef"
                    :model="editedPolicy"
                    label-position="top"
                    :validate-on-rule-change="false"
                >
                
                <div class="container">
                    <UpdatePolicyDetails ref="updatePolicyDetails" :form="ruleFormRef" :is-draft="isDraft" />
                    <UpdateCreditAndDebitNoteInformation ref="updateDebitCredit" :form="ruleFormRef" :is-draft="isDraft" v-if="!editedPolicy.is_view && editedPolicy.pending && editedPolicy.pending.length > 0" />
                    <CompletedDetails v-if="store.state.Policy.completedInvoices.length > 0" />
                    <UpdateDocuments ref="updateDocuments" @deleteDocument="deleteDocument" @refresh="passValue" v-if="!editedPolicy.is_view" />
                    <!-- <el-form-item prop="requiredDoc" :rules="{ validator: isDraft ? Validations.policy_required_doc_draft : Validations.policy_required_doc, trigger: ['blur', 'change'] }" >
                        <el-input
                            v-if="!isView && !isDisabled"
                            style="display:none"
                            v-model="editedPolicy.requiredDoc"
                            ref="requiredDoc"
                        />
                    </el-form-item> -->
                    <UploadInstallationDocuments v-if="store.state.Policy.installmentDocuments.length > 0" />
                    <EmailActivity v-if="store.state.Policy.policyEmails.length > 0" />
                </div>
                <div class="card-footer text-center">
                        <div v-if="editedPolicy.status < 4">
                            
                            <button
                                @click="toPolicy" 
                                class="btn btn-secondary me-3"
                            >
                                Cancel
                            </button>
    
                            <button
                                :disabled="saveAsDraftLoading || saveLoading || isPolicySubmitted"
                                type="button"
                                @click="uploadPolicy(ruleFormRef)"
                                class="btn btn-info ms-5"
                            >
                            <span v-if="!saveLoading" class="indicator-label">
                                Submit
                            </span>
                            <span v-else>
                                Please wait...
                                <span
                                class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span>
                            </span>
                            </button>
    
                            <button
                            type="button"
                            @click="saveAsDraft(ruleFormRef)"
                            class="btn btn-info ms-5"
                            :disabled="editedPolicy.status === 4 || saveAsDraftLoading || saveLoading ||isPolicySubmitted"
                            >
                            <span
                                v-if="!saveAsDraftLoading"
                                class="indicator-label"
                            >
                                Save To Draft
                            </span>
                            <span v-else>
                                Please wait...
                                <span
                                class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span>
                            </span>
                            </button>
                            
                        </div>
                        
                        <div v-if="editedPolicy.status == 4">
                        
                            <button
                            :disabled="saveLoading || isPolicySubmitted"
                            type="button"
                            @click="uploadPolicy(ruleFormRef)"
                            class="btn btn-info"
                            >
                            <span v-if="!saveLoading" class="indicator-label">
                                Submit
                            </span>
                            <span v-else >
                                Please wait...
                                <span
                                class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span>
                            </span>
                            </button>
                    
                        </div>
                        </div>
                </el-form>
            </div>
        </div>

</template>

<script setup>
import { ref, reactive, computed, h, onMounted } from 'vue';
import { usePolicyDetails } from '@/store/composable/Policy'
import UpdatePolicyDetails from '@/views/critical-illness/UpdatePolicy/UpdatePolicyDetails.vue'
import UpdateCreditAndDebitNoteInformation from '@/views/critical-illness/UpdatePolicy/UpdateCreditAndDebitNoteInformation.vue'
import UpdateDocuments from '@/views/critical-illness/UpdatePolicy/UpdateDocuments.vue'
import CompletedDetails from '@/views/critical-illness/UpdatePolicy/CompletedDetails.vue'
import UploadInstallationDocuments from '@/views/critical-illness/UpdatePolicy/UploadInstallationDocuments.vue'
import EmailActivity from '@/views/critical-illness/UpdatePolicy/EmailActivity.vue'
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { ElDivider } from "element-plus";
import { useUpdateCustomerData, useUpdateError } from '@/store/composable/Customer'
import { notif } from '@/store/stateless/store';
import { updatePolicyDetailsProp } from '@/core/utils/Policy'
import { sendMails } from '@/core/utils/Policy'


const spacer = h(ElDivider, { direction: "vertical" })
const ruleFormRef = ref()
const store = useStore()
const route = useRoute()
const router = useRouter()
const emailLoading = ref(false)
const isDraft = ref(false)
const saveAsDraftLoading = ref(false)
const saveLoading = ref(false)
const updatePolicyDetails = ref()
const updateDebitCredit = ref()

const mails = reactive({
    email_customer: false,
    email_broker: false,
})

usePolicyDetails(store, route.query && route.query.isView, route.params.policy_id)

const policy = computed(() => store.state.Policy.policy)
const editedPolicy = computed(() => store.state.Policy.editPolicy)
const loading = computed(() => store.state.Policy.editPolicyLoading)
const isPolicySubmitted = computed(() => store.state.Policy.editPolicySubmitted)

onMounted(() => {
    setTimeout(() => {
        ruleFormRef.value.clearValidate('insurance_payment')
    }, 1000);
})

function deleteDocument() {
    alert('delete')
}

async function passValue(emit_data) {
    const data = {
        linkType: 'critical-illness-documents',
        invoice_id: editedPolicy.value.parent_invoice_id,
        categories: 'standard',
        withDelete: true,
        withLog: false,
        viewAll: true,
        document_type: ['verification', 'policy', 'credit debit'],
    }

    const response = await useUpdateCustomerData(data)
    if(!emit_data || (emit_data && !emit_data.set_policy_main_document))
        store.commit('SET_POLICY_MAIN_DOCUMENT', response.data.data.documents)
}

async function uploadPolicy(formEl) {
    isDraft.value = false
    saveLoading.value = true
    const form = {
            linkType: 'critical-illness-submit-policy',
            ...renderValue(editedPolicy.value)
        }

        setTimeout(() => {
        formEl.validate(async(valid, fields) => {
            if(valid) {
                const response = await useUpdateCustomerData(form)
                store.commit('SET_POLICY_SUBMITTED', true)
                if(response.status < 299) {
                    notif.simple('Policy Submitted', 'success', response.data.message)
                    toPolicy()
                } else {
                    notif.simple('Please Note', 'warning', response.response.data.message)
                }
            } else {
                saveLoading.value = false
                const firstError = Object.keys(fields)[0]
                useUpdateError(firstError)
                if(updatePolicyDetailsProp.includes(firstError)) updatePolicyDetails.value.focus()
                else updateDebitCredit.value.focus()
            }
            saveLoading.value = false
        })
    }, 500);
}

async function sendEmail() {
    if(mails.email_customer || mails.email_broker) {
        emailLoading.value = true
        Object.values(mails).forEach(async(x, index) => {
            let form = {
                linkType: '',
                policy_id: editedPolicy.value.policy_id,
            }
            if(x) {
                form.linkType = index == 1 ? 'critical-illness-policy-send-email-to-broker' : 'critical-illness-policy-send-email-to-customer'
                const response = await useUpdateCustomerData(form)
                notif.simple('Email Sent', 'success', response.data.message)
            }
        })

        setTimeout(async() => {
            await toPolicy(false)
            emailLoading.value = false
        }, 1500);

    }
}

function saveAsDraft(formEl) {
    isDraft.value = true
    saveAsDraftLoading.value = true

    const form = {
            linkType: 'critical-illness-save-as-draft',
            ...renderValue(editedPolicy.value)
        }

        formEl.clearValidate()
        if(!formEl) {
            saveAsDraftLoading.value = false
            return
        }
        
    setTimeout(() => {
        formEl.validate(async(valid, fields) => {
            if(valid) {
                const response = await useUpdateCustomerData(form)
                if(response.status < 299) {
                    notif.simple('Policy Saved to Draft', 'success', response.data.message)

                }
            } else {
                saveAsDraftLoading.value = false
                const firstError = Object.keys(fields)[0]
                useUpdateError(firstError)
                if(updatePolicyDetailsProp.includes(firstError)) updatePolicyDetails.value.focus()
                else updateDebitCredit.value.focus()
            }
            saveAsDraftLoading.value = false
        })
    }, 500);


}

function renderValue(data) {
    const emirates_id = checkEmiratesIDFormat(data.emirates_id) ? data.emirates_id : ''
    const pending = data.pending.map(pending => {
        return {
            ...pending,
            credit_amount: Number(pending.credit_amount),
            debit_amount: Number(pending.debit_amount),
        }
    })
    return {
        ...data,
        pending,
        emirates_id
    }
}

function checkEmiratesIDFormat(text) {
    const regex = /^\d{3}-\d{4}-\d{7}-\d{1}$/;
    return regex.test(text);
}

async function toPolicy(isCompleted = true) {
    let route_name = 'critical-illness-policy-assigned'
    if(editedPolicy.value.status === 4 && isCompleted) 
        route_name = 'critical-illness-policy-completed'

    await router.push({ name: route_name })
        .then(() => router.go(0))
}

</script>

<style lang="scss">
.header-min-height {
min-height: 10px !important;
}
</style>