<template>
    <div>
        <div class="forgot-password-area">
            <div class="d-flex align-items-center justify-content-center h-100 forgot-password-icon">
            <img alt="Logo" src="media/logos/create-new-password.png" class="h-45px" />
                
            </div>
            <div class="text-center forgot-password">
                Create new password
            </div>
            <div class="text-center forgot-description">
                Enter the new password for your account.
            </div>
        </div>
        <el-form
                ref="ruleFormNameRef"
                class="forgot-password-form-new"
                :model="reset"
                :rules="rules"
                label-position="top"
                autocomplete="off"
            >
                <el-form-item prop="password">
                    <label for="user-password"> New Password </label>
                    <el-tooltip effect="light" open-delay="200" placement="bottom-start">
                        <template #content>
                            <span class="svg-icon svg-icon-2hx me-4" :class="isLengthValid ? 'svg-icon-success' : 'svg-icon-danger'">
                                <inline-svg :src="`media/icons/duotune/general/${isLengthValid ? 'gen037' : 'gen034'}.svg`" /> Password length >= 18
                            </span>
                            <br />
                            <span class="svg-icon svg-icon-2hx me-4" :class="hasUppercase ? 'svg-icon-success' : 'svg-icon-danger'">
                                <inline-svg :src="`media/icons/duotune/general/${hasUppercase ? 'gen037' : 'gen034'}.svg`" /> Upper case
                            </span>
                            <br />
                            <span class="svg-icon svg-icon-2hx me-4" :class="hasLowercase ? 'svg-icon-success' : 'svg-icon-danger'">
                                <inline-svg :src="`media/icons/duotune/general/${hasLowercase ? 'gen037' : 'gen034'}.svg`" /> Lower case
                            </span>
                            <br />
                            <span class="svg-icon svg-icon-2hx me-4" :class="hasDigit ? 'svg-icon-success' : 'svg-icon-danger'">
                                <inline-svg :src="`media/icons/duotune/general/${hasDigit ? 'gen037' : 'gen034'}.svg`" /> Digit
                            </span>
                            <br />
                            <span class="svg-icon svg-icon-2hx me-4" :class="hasSpecialChar ? 'svg-icon-success' : 'svg-icon-danger'">
                                <inline-svg :src="`media/icons/duotune/general/${hasSpecialChar ? 'gen037' : 'gen034'}.svg`" /> Special character
                            </span>
                        </template>
                        <el-input
                            id="user-password"
                            v-model="reset.password"
                            placeholder="Enter new Password"
                            ref="password"
                            maxlength="25"
                            @input="Validations.is_password_used = false"
                            show-password
                            autocomplete="new-password"
                        />
                    </el-tooltip>
                </el-form-item>
                <el-form-item prop="confirm_password">
                    <label for="user-confirm-password"> Confirm New Password </label>
                    <el-input
                        id="user-confirm-password"
                        v-model="reset.confirm_password"
                        placeholder="Confirm new password"
                        ref="confirm_password"
                        maxlength="25"
                        show-password
                        autocomplete="new-password"
                    />
                </el-form-item>
                <el-form-item prop="code">
                    <label for="user-code"> 
                        Security Code 
                        <el-tooltip
                            class="box-item cursor-pointer"
                            effect="light"
                            placement="top"
                            :show-after="500"
                            :hide-after="0"
                        >
                            <template #content>Code sent on registered email id</template>
                            <i class="fas fa-info-circle text-info"></i>
                        </el-tooltip>
                    </label>
                    <el-input
                        id="user-code"
                        v-model="reset.code"
                        placeholder="Security code"
                        ref="code"
                        maxlength="6"
                        @input="Validations.code_not_valid = false; Validations.code_expired = false;"
                        style="max-width: 180px;"
                        autocomplete="off"
                    />
                </el-form-item>
            
            </el-form>

        <div class="d-flex justify-content-center">
            <button
                type="button"
                class="btn btn-lg btn-light w-50 mt-5 me-2"
                @click="$emit('cancel')"
            >
                Cancel
            </button>
            <button
                type="button"
                class="btn btn-lg btn-info w-50 mt-5 ms-2"
                @click="handleSubmit(ruleFormNameRef)"
                :disabled="loading"
            >
                Save
            </button>
        </div>
    </div>
</template>

<script setup>
    import Validations from '@/core/services/etc/Validations'
    import { reactive, computed, ref, defineEmits } from 'vue';
    import { useStore } from 'vuex'
    import { notif } from "@/store/stateless/store";
    import { useUpdateCustomerData } from '@/store/composable/Customer'
    import JwtService from "@/core/services/JwtService";
    import { Actions, Mutations } from "@/store/enums/StoreEnums";
    import { useRouter } from "vue-router";
    
    const store = useStore()
    const router = useRouter();
    const ruleFormNameRef = ref()
    const password = ref()
    const code = ref()
    const loading = ref(false)

    const emit = defineEmits(['cancel'])

    const reset = computed(() => {
        return store.state.AuthModule.resetPassword
    })

    const rules = reactive({
        password: [{ validator: Validations.passwordStrong, trigger: ['blur', 'change'] }],
        code: [{ validator: Validations.code, trigger: ['blur', 'change'] }],
        confirm_password: [{ validator: (rule, value, callback) => Validations.confirm_password(rule, value, callback, reset.value.password), trigger: ['blur', 'change'] }],
    })

    const isLengthValid = computed(() => {
        const lengthRequirement = /.{18,}/
        return lengthRequirement.test(reset.value.password)
    })

    const hasUppercase = computed(() => {
        const uppercaseRequirement = /[A-Z]/
        return uppercaseRequirement.test(reset.value.password)
    })

    const hasLowercase = computed(() => {
        const lowercaseRequirement = /[a-z]/
        return lowercaseRequirement.test(reset.value.password)
    })

    const hasDigit = computed(() => {
        const digitRequirement = /\d/
        return digitRequirement.test(reset.value.password)
    })

    const hasSpecialChar = computed(() => {
        const specialCharRequirement = /[!@#$%^&*(),.?":{}|<>]/
        return specialCharRequirement.test(reset.value.password)
    })

    async function handleSubmit(formEl) {
        resetValidations()

        if(!formEl) return

        formEl.validate(async(valid) => {
            if(valid) {
                reset.value.linkType = 'reset-password'

                loading.value = true
                const response = await useUpdateCustomerData(reset.value)
                loading.value = false

                if(response.status < 299) {
                    store.commit(Mutations.SET_AUTH, response.data)
                    store.commit(Mutations.SET_USER_ACL, response.data)
                    JwtService.saveToken(response.data.data.access_token)
                    window.localStorage.setItem('refresh_token', response.data.data.refresh_token)
                    window.localStorage.setItem('expires_in', response.data.data.expires_in)
                    
                    const uid = window.localStorage.getItem('uid');
                    const user = store.getters.currentUser;
                    const localStorageRoute = window.localStorage.getItem("currentRoute");
                    const currentRoute = localStorageRoute ? JSON.parse(localStorageRoute) : null;

                    notif.simple(
                        "Reset Password",
                        "success",
                        "You have sucessfully reset the password!",
                        4500,
                        "notification-success"
                    );

                    const products = response.data.data.products
                    const firstProduct = products[0].value
                    const toDashboard = `/${firstProduct}/dashboard`

                    // await store.dispatch(Actions.GET_ROUTE_LIST, { type: products[0].value });
                    window.localStorage.setItem('uid', response.data.data.user.id);
                    
                    emit('cancel')

                        return await router.push({ path: ((currentRoute && uid == user.id) 
                                                              ? currentRoute 
                                                              : toDashboard) })
                                .then(() => { router.go(0) })

                    
                    
                    
                } else {
                    if (response.response.data.data.password_is_used) {
                        Validations.is_password_used = true;
                        formEl.validateField('password')
                        password.value.focus()
                    } else if (response.response.data.data.code_not_valid) {
                        Validations.code_not_valid = true;
                        formEl.validateField('code')
                        code.value.focus()
                    } else if (response.response.data.data.code_expired) {
                        Validations.code_expired = true;
                        formEl.validateField('code')
                        code.value.focus()
                    }
                }
            }
        })
    }

    function resetValidations() {
        Validations.user_name_not_found = false
        Validations.is_password_used = false
        Validations.user_name_not_active = false
        Validations.code_not_valid = false
        Validations.code_expired = false
        Validations.unauthorized = false
    }

</script>
<style>
    .forgot-password-area {
        margin-bottom: 35px;
    }

    /* .forgot-password-icon {
        margin-top: 3vh;  
    } */

    .forgot-password {
        font-size: 25px;
        font-weight: 500;
        color: #222261;
        line-height: 28px;
        margin: 12px;
        word-break: break-word;
    }
    .forgot-description {
        margin: 10px;
        word-break: break-word;
    }

    .forgot-password-form-new .el-form-item__content {
        line-height: 18px
    }

    .password-reset-dialog .el-form-item:last-child .el-form-item__content { display: flex; flex-direction: column;} 
    .password-reset-dialog .el-form-item {
        margin-bottom: 28px;
    }
</style>