import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
// import LocalStorageServices from "@/core/services/LocalStorageServices";
import { ElNotification } from "element-plus";
import Layout from '@/layout/Layout.vue'
import { travel } from "./routes/travel";
import { critical_illness } from "./routes/critical_illness";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import('@/views/Blank.vue'),
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      const products = JSON.parse(window.localStorage.getItem('products')!)


      if(!products) {
        window.localStorage.removeItem('myRoute');
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('expires_in');
        window.localStorage.removeItem('user');
        next({
            path: "/sign-in"
        });
      }


      if(products) {
        window.history.replaceState({...history.state}, '', `/#/${products[0].value}/dashboard`)
        window.location.reload()
        next()
      }
    }
  },
  {
    path: "/travel",
    redirect: "/travel/dashboard",
    component: () => import("@/layout/Layout.vue"), 
    meta: {
      requiresAuth: true,
      product: 'travel'
    },
    children: travel,
    beforeEnter: async (to, from, next) => {
      const products = window.localStorage.getItem('products');
      if(products) {
        const encode = JSON.parse(products)
        const has_access = encode.find(prod => prod.value == 'travel' )
        if(has_access) {
          await store.dispatch(Actions.GET_ROUTE_LIST, { type: 'travel' })
          next()
        } else {
          next(`/${encode[0].value}/dashboard`)
        }
      }
    }
  },
  {
    path: "/critical-illness",
    redirect: "/critical-illness/dashboard",
    component: () => import("@/layout/Layout.vue"), 
    meta: {
      requiresAuth: true,
      product: 'critical-illness'
    },
    children: critical_illness,
    beforeEnter: async (to, from, next) => {

      const products = window.localStorage.getItem('products');
      if(products) {
        const encode = JSON.parse(products)
        const has_access = encode.find(prod => prod.value == 'critical-illness' )
        if(has_access) {
          await store.dispatch(Actions.GET_ROUTE_LIST, { type: 'critical-illness' })
          next()
        } else {
          next(`/${encode[0].value}/dashboard`)
        }
      }
    }
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: {
      requiresVisitor: true
    },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/authentication/SignIn.vue"),
      },
      {
        path: "/auth/token/:token",
        name: "fetchToken",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Authentication.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // path: "/sales",
    // component: () => import("@/components/page-layouts/Achievement.vue"),
    path: "/sales/achievements",
    name: "sales-achievements",
    component: () => import("@/views/SalesAchievements.vue"),
    // redirect: 'achievements',
    meta: {
      requiresAuth: true
    },
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/sign-in",
  },
  {
    path: "/switch-app/:switchTo/:refreshToken",
    name: "switch-app",
    component: () => import("@/views/SwitchApp.vue"),
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});



router.beforeEach((to, from, next) => {
const token = window.localStorage.getItem('id_token');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!token) {
      // window.localStorage.removeItem('currentRoute');
      window.localStorage.removeItem('myRoute');
      window.localStorage.removeItem('id_token');
      window.localStorage.removeItem('refresh_token');
      window.localStorage.removeItem('expires_in');
      window.localStorage.removeItem('user');
        next({
            path: "/sign-in"
        });
        
    } else {
        next();
    }
} else if(to.matched.some(record => record.meta.requiresVisitor)) {
  if(token) {
    const products = JSON.parse(window.localStorage.getItem('products')!)
    window.history.replaceState({...history.state}, '', `/#/${products[0].value}/dashboard`)
      window.location.reload()
    next()
  } else {
    // window.localStorage.removeItem('currentRoute');
    window.localStorage.removeItem('myRoute');
    window.localStorage.removeItem('id_token');
    window.localStorage.removeItem('refresh_token');
    window.localStorage.removeItem('expires_in');
    window.localStorage.removeItem('user');
    next();
  }
}  else {
  next();
}

setTimeout(() => {
  ElNotification.closeAll();
}, 2000);


  // reset config to initial state

  // console.log(LocalStorageServices.getUser());
  // console.log(store.getters.getUserPermissions);
  // userStr = LocalStorageServices.getUser();
  // userObj = JSON.parse(userStr);
  // if (userObj && userObj.role_id == 2) {
  //   routes = sales_routes;
  // }
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

/**
 * it was commented. 
 * Uncommented by Ashwin B
 */
  // store.dispatch(Actions.VERIFY_AUTH);
  const myRoute = window.localStorage.getItem('myRoute')
  if(!myRoute) {
    // const token = window.localStorage.getItem('id_token');
    // if(token) {
    //   store.dispatch(Actions.GET_ROUTE_LIST, { type: "misk" });
    // }
  }else {
    store.commit(Mutations.SET_ROUTE_LIST, JSON.parse(myRoute))
  }

  // const authToken = window.localStorage.getItem('id_token');
  if(token){
    const tokenCheckedAt = window.localStorage.getItem('token_lc');
    const tokenTimeLimit = 45;
    /**
     * Checking if the last checked time is past 45 minutes of last check
     */
    // const recheckToken   = (tokenCheckedAt) 
    //                           ? ((new Date()) > (new Date(new Date(tokenCheckedAt).getTime() + tokenTimeLimit * 60 * 1000)))
    //                           : true;
    const recheckToken = true;
    if(recheckToken){
      store.dispatch(Actions.GET_NEW_AUTH_CHECK, { token: recheckToken });
    }    
  }


  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;