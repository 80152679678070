<template>
    <div
        v-loading="loading"
        element-loading-background="rgba(122, 122, 122, 0.1)"
        element-loading-text="Please wait"
        :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        style="z-index: 1"
    >
        <vc-sockets  :channel="`ci-invoice-list`" listen="GlobalNotificationToAppEvent" @action="listing(CiInvoiceList.page, false)" />

        <!-- <vc-sockets :channel="`policy-channel`" listen="CarPolicyCountSocketEvent" @action="listing(CiInvoiceList.page, false)" /> -->
        <!-- <vc-sockets v-if="user_id" :channel="`channel-${user_id}`" listen="CarInvoiceSocketEvent" @action="listing(CiInvoiceList.page, false)" /> -->

        <div class="card card-policy-assigned">
            <div class="container container-lead bg-white border-radius-4">
                <InvoiceFirst :transaction-types="payments" :installmentStatus="installmentStatus" @refresh="listing(CiInvoiceList.page, false)" />
                <InvoiceSecond :task-counts="dueCounts" v-if="route.name == 'critical-illness-approved-invoice'" />
            </div>
        </div>
        <UploadReciept :invoice_id="invoice_id" ref="upload_receipt" @refresh="listing(CiInvoiceList.page, false)" />
        <InvoiceDetails :data="invoiceData" ref="invoice_details" :isViewInstallment="viewInstallment" />
        <InvoiceModal 
            :data="invoiceData" 
            ref="invoice_ref" 
            :message="selectedOpt.name" 
            :quote_ref_no="selectedRow.quote_ref_no" 
            :is-loading="loadingModal"
            :has-error="invoiceAlreadyActioned"
            :actioned-message="invoiceAlreadyActionedMessage"
            @cancel="cancelOpt"
            @submit="SubmitOpt"
            @contData="continueData"
        />
        <InvoiceTable :data="datas" :loading="loadOnce" :footer-data="footerData" @action="action" @refresh="listing(CiInvoiceList.page, false)" @process="handleOption" />
        
    </div>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useListing, CiInvoiceList, resetCiInvoiceList } from '@/store/composable/Listing'
import { useUpdateCustomerData } from '@/store/composable/Customer'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useUser } from '@/store/composable/User'
import InvoiceFirst from "@/components/NEW/Invoice/invoiceFirst.vue";
import InvoiceTable from "@/components/NEW/Invoice/invoiceTable.vue";
import InvoiceModal from "@/components/NEW/Invoice/invoiceModal.vue";
import InvoiceDetails from "@/components/NEW/Invoice/invoiceDetails.vue";
import UploadReciept from "@/components/NEW/Invoice/uploadReciept.vue";
import InvoiceSecond from "@/components/NEW/Invoice/invoiceSecond.vue";

const store = useStore()
const loading = ref(false)
const loadOnce = ref(true)
const route = useRoute()
const datas = ref([])
const invoice_ref = ref()
const selectedRow = ref({})
const selectedOpt = ref({})
const loadingModal = ref(false)
 const { user_id } = useUser()

onMounted(() => {
    getPayments()
    let type = 'all'
    if(route.name == 'critical-illness-approval-invoice') type = 'cod_direct_intransact'
    if(route.name == 'critical-illness-decline-invoice') type = 'declined'
    if(route.name == 'critical-illness-invoice-installments') {
        type = 'installments'
        getInstallmentStatus()
    }

    resetCiInvoiceList(type, null, route.name)
    
    // listing(CiInvoiceList.value.page)

})

onBeforeUnmount(() => {
    let type = 'all'
    if(route.name == 'critical-illness-approval-invoice') type = 'cod_direct_intransact'
    if(route.name == 'critical-illness-decline-invoice') type = 'declined'
    if(route.name == 'critical-illness-invoice-installments') type = 'installments'
    resetCiInvoiceList(type, null, route.name)
})

watch(() => CiInvoiceList.value, async(invoice) => {
    await listing(invoice.page)
}, { deep: true })

const counts = ref({})
async function count() {
    console.log('check')
    if(route.name == 'critical-illness-approved-invoice'){
        let linkType =  'critical-illness-invoice-count'
        const params = {
            linkType,
            ...CiInvoiceList.value
        }
        const response = await useUpdateCustomerData(params)
        counts.value = response.data.data.invoices
    }
}

const total = ref(0)
const footerData = ref({
    from: 0,
    to: 0,
    total: 0
})
async function listing(e, forceLoad = true) {
    if(forceLoad) loading.value = true

    window.scrollTo({ top: 0, behavior: 'smooth' })
    const listing = await useListing(CiInvoiceList.value, route.name)
   
    datas.value = listing.data.data.invoices.data

    total.value = listing.data.data.invoices.total
    CiInvoiceList.value.page = listing.data.data.invoices.current_page
    CiInvoiceList.value.per_page = listing.data.data.invoices.per_page
    footerData.value.from = listing.data.data.invoices.from
    footerData.value.to = listing.data.data.invoices.to
    footerData.value.total = listing.data.data.invoices.total
    loading.value = false
    loadOnce.value = false
    await count()

}

const dueCounts = computed(() => {
    if(Object.values(counts.value).length > 0) return { ...counts.value }

    
        return {
            all: 0,
            amend_invoice: 0,
            new: 0,
        }
})

const payments = ref([])
async function getPayments() {
    const reason = await useUpdateCustomerData({ linkType: 'critical-illness-payment-types' })
    
    let response = reason.data.data.types

    payments.value = response
}
const installmentStatus = ref([])
async function getInstallmentStatus() {
    const reason = await useUpdateCustomerData({ linkType: 'critical-illness-installment-status' })
    
    let response = reason.data.data.types

    installmentStatus.value = response
}

const invoice_id = ref(0)
const invoiceData = ref()
const viewInstallment = ref(false)
const inst = getCurrentInstance()
async function action(value, id) {
    console.log({ value, id })
    if(value == 'invoice_details') {
        const params = {
            linkType: 'critical-illness-invoice-data',
            invoice_id: id
        }
        const response = await useUpdateCustomerData(params)
        console.log('response',response.data.data)
        invoiceData.value = response.data.data
        viewInstallment.value = false
    }

    if(value == 'installments') {
        const params = {
            linkType: 'critical-illness-view-installments',
            invoice_id: id
        }
        const response = await useUpdateCustomerData(params)
        console.log('response',response.data.data)
        invoiceData.value = response.data.data
        value = 'invoice_details'
        viewInstallment.value = true
    }

    invoice_id.value = id
    const item = inst.refs[value]
    setTimeout(() => {
        if(item != undefined) Array.isArray(item) ? item[0].open() : item.open()
    }, 500);
}

function handleOption(data, opt) {
    invoice_ref.value.open()
    selectedRow.value = data
    selectedOpt.value = opt
}

function cancelOpt() {
    datas.value = datas.value.map(x => {
        let status = 1
        if(x.tab == 'discrepancy_count')
            status = 2

        return {
            ...x,
            status
        }
    })
}
const invoiceAlreadyActioned = ref(false)
const invoiceAlreadyActionedMessage = ref('')

function continueData() {
    invoiceAlreadyActioned.value = false
    invoiceAlreadyActionedMessage.value = ''
    invoice_ref.value.open(false)
}

async function SubmitOpt() {
    invoiceAlreadyActioned.value = false
    invoiceAlreadyActionedMessage.value = ''

    const data = {
        invoice_id: selectedRow.value.invoice_id,
        status: selectedOpt.value.id,
        item: selectedRow.value,
        linkType: 'critical-illness-update-invoice'
    }
    loadingModal.value = true

    const response = await useUpdateCustomerData(data)
    if(response.status == 200 && response.data.data.approved_invoice) {
        invoiceAlreadyActioned.value = true
        invoiceAlreadyActionedMessage.value = response.data.message
        invoice_ref.value.open()
    } else {
        await listing(CiInvoiceList.value.page, false)
        invoice_ref.value.open(false)
        loadingModal.value = false
    }
    }

</script>
